import { Container, Flex } from "@mantine/core";
import ImageDoubleQuestionQuiz from "../../../models/Quizes/ImageDoubleQuestionQuiz";
import ImageQuiz from "../../../models/Quizes/ImageQuiz";
import Quiz from "../../../models/Quizes/Quiz";
import TextPromptQuiz from "../../../models/Quizes/TextPromptQuiz";
import TextQuiz from "../../../models/Quizes/TextQuiz";
import ImageDoubleQuestion from "../ImageDoubleQuestion/image-double-question.component";
import ImageQuizComponent from "../ImageQuiz/image-quiz.component";
import TextPromptComponent from "../TextPrompt/text-prompt.component";
import TextQuizComponent from "../TextQuiz/text-quiz.component";
import { UserAnswer } from "../../../types/UserAnswer";
import { IconStar, IconStarFilled, IconBookmark, IconBookmarkFilled } from "@tabler/icons-react";
import { useInjection } from "inversify-react";
import IFavoriteQuestionRepositoryService from "../../../services/IFavoriteQuestionRepositoryService";
import { ActionIcon } from '@mantine/core';
import { useState } from "react";

interface QuizRendererProps {
  index?: number;
  quiz: any;
  markAnswer?: boolean;
  userAnswer?: UserAnswer;
  disabled?: boolean;
  onAnswer?: (userAnswer: UserAnswer) => void;
  layoutForm? : boolean,
  favoriteIcon? : boolean
}

export default function QuizRendererComponent(props: QuizRendererProps) {
  const { quiz, index, onAnswer, favoriteIcon } = props;

  const [refresh, setRefresh] = useState(false)

  const favoriteRepositoryService = useInjection<IFavoriteQuestionRepositoryService>('IFavoriteQuestionRepositoryService')

  const quizCasted = quiz as Quiz;

  const sharedAttributes = {
    markAnswer: props.markAnswer,
    disabled: props.disabled,
  };

  const quizElement = (function () {
    switch (quizCasted.key) {
      case "textquiz":
        const textQuiz = quiz as TextQuiz;
        textQuiz.correctAnswer = parseInt(quiz.correctAnswer);
        return (
          <TextQuizComponent
            userAnswer={
              props.userAnswer!==undefined ? (props.userAnswer as number) : undefined
            }
            textQuiz={quiz as TextQuiz}
            onAnswer={(index) => {
              if (!!onAnswer) onAnswer(index);
            }}
            {...sharedAttributes}
          />
        );
      case "textprompt":
        return (
          <TextPromptComponent
            userAnswer={
              props.userAnswer!==undefined ? (props.userAnswer as string) : undefined
            }
            onAnswer={(response) => {
              if (!!onAnswer) onAnswer(response);
            }}
            textPrompt={quiz as TextPromptQuiz}
            {...sharedAttributes}
          />
        );
      case "imagequiz":
        const imageQuiz = quiz as ImageQuiz;
        imageQuiz.correctAnswer = parseInt(quiz.correctAnswer);
        return (
          <ImageQuizComponent
            userAnswer={
              props.userAnswer!==undefined ? (props.userAnswer as number) : undefined
            }
            onAnswer={(response) => {
              if (!!onAnswer) onAnswer(response);
            }}
            imageQuiz={imageQuiz}
            {...sharedAttributes}
          />
        );
      case "imageDoubleQuestion":
        return (
          <ImageDoubleQuestion
            longView={props.layoutForm}
            userAnswer={
              props.userAnswer!==undefined
                ? (props.userAnswer as (number | string)[])
                : undefined
            }
            onAnswer={(response) => {
              if (!!onAnswer) onAnswer(response);
            }}
            imageQuiz={quiz as ImageDoubleQuestionQuiz}
            {...sharedAttributes}
          />
        );
      default:
        return <p>{quizCasted.key}</p>;
    }
  })();

  const favoriteButton = (function(){
    const isFavorite = favoriteRepositoryService.isFavorite(quiz.id);
    if(!!favoriteIcon){
        return (
            <ActionIcon
             color={isFavorite?"red":'gray'}
             onClick={e=>{
                e.preventDefault();
                favoriteRepositoryService.setFavorite(quiz.id,!isFavorite)
                setRefresh(!refresh);
            }}>
                {!!isFavorite?<IconBookmarkFilled color="red"/> : <IconBookmark/>}
            </ActionIcon>
        )
    }
    return (<></>)
  })()

  const topBar = (function(){
    return (
    <Flex dir="row" justify='space-between'>
        {!!index && <p>{`Question #${index}`}</p>}
        {favoriteButton}
    </Flex>)
  })();

  return (
    <Container
      sx={{
        width: "100%",
        borderColor: "black",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
        {topBar}
      {quizElement}
    </Container>
  );
}
