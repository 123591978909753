import { Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import styles from "./top-bar.module.scss";

export default function TopBar() {
  return (
    <div className={styles.bar}>
      <div className={styles.left}>
        <img alt="logo" src="../logo512.png"/>
        <Link className={styles.navLink} to="/review">
          Review
        </Link>
        <Link className={styles.navLink} to="/questions">
          Questions
        </Link>
        <Link className={styles.navLink} to="/saved">
          Saved
        </Link>
      </div>
    </div>
  );
}
