import { Flex, Container, Input, Button } from "@mantine/core";
import Question from "../../Basic/Question/question.component";
import HintComponent from "../Hint/hint.component";
import TextPromptQuiz from "../../../models/Quizes/TextPromptQuiz";
import { useState } from "react";

interface TextPromptComponentProps {
  textPrompt: TextPromptQuiz;
  markAnswer?: boolean;
  userAnswer?: string;
  disabled?: boolean;
  onAnswer?: (answer: string) => void;
}

export default function TextPromptComponent(props: TextPromptComponentProps) {
  const { textPrompt, markAnswer, userAnswer, disabled, onAnswer } = props;

  const [promptAnswer, setPromptAnswer] = useState("");

  const isCorrect = function(){
    if(!userAnswer)
        return false;


    if(userAnswer.replaceAll(' ', '') === textPrompt.correctAnswer.replace(' ', ''))
        return true;

    return false;
  }()

  return (
    <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Question>{textPrompt.question}</Question>
      <Container>
        <Flex
          mih={50}
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          sx={{
            width: "100%",
          }}
        >
          {!markAnswer && (
            <Input
              value={promptAnswer}
              onChange={(e) => {
                e.preventDefault();
                setPromptAnswer(e.target.value);
              }}
            />
          )}
          {!!markAnswer && <Input disabled style={{outline: !!isCorrect?"green solid 2px":undefined}} value={textPrompt.correctAnswer} />}
          {!!markAnswer && !!userAnswer && !isCorrect && <Input style={{outline: "red solid 2px"}} disabled value={userAnswer} />}
          <HintComponent quiz={textPrompt} />

          {!markAnswer && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                if (!!onAnswer && !disabled) onAnswer(promptAnswer);
              }}
            >
              Answer
            </Button>
          )}
        </Flex>
      </Container>
    </Flex>
  );
}
