import { Button } from "@mantine/core";

export enum AnswerState {
  CorrectAnswer,
  Default,
  WrongAnswer,
}

export default function Answer({
  children,
  answerState,
  clicked,
  disabled
}: {
  answerState: AnswerState;
  children?: string;
  clicked?: () => void;
  disabled? : boolean
}) {
  let color = "dark";

  if (!!answerState) color = "dark";

  if (answerState === AnswerState.CorrectAnswer) color = "green";

  if (answerState === AnswerState.WrongAnswer) {
    color = "red";
  }

  return (
    <Button
      variant={
        answerState !== AnswerState.Default
          ? "filled"
          : "outline"
      }
      color={color}
      styles={(theme)=>({
        label:{
            textOverflow: "inherit",
            overflowWrap : "normal",
            textWrap : 'wrap'
        }
      })}
      sx={{
        width:'80%',
        height:!!children && children.length>80?'60px':undefined,
        lineHeight: !!children && children.length>80?'18px':undefined
      }}
      onClick={(e) => {
        e.preventDefault();
        if (!!clicked && !disabled) clicked();
      }}
    >
      {children}
    </Button>
  );
}
