import { AppShell, Header } from "@mantine/core";
import TopBar from "../components/TopBar/top-bar.component";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ReviewPage from "./ReviewPage";
import UserQuizPage from "./UserQuizPage";
import QuestionSetsPage from "./QuestionSetsPage";
import Footer from "../components/footer/footer.component";
import FavoriteQuestionPage from "./FavoriteQuiestionPage";

export default function RouterPage() {
  return (
    <BrowserRouter>
      <AppShell
        padding="md"
        header={
          <Header height={60} p="xs">
            <TopBar />
          </Header>
        }
        footer={
            <Footer/>
        }
      >
        <Routes>
          <Route path="/" element={<ReviewPage />} />
          <Route path="/review" element={<ReviewPage />} />
          <Route path="/questions/:setNumber" element={<UserQuizPage />} />
          <Route path="/questions" element={<QuestionSetsPage />} />
          <Route path="/saved" element={<FavoriteQuestionPage />} />
        </Routes>
      </AppShell>
    </BrowserRouter>
  );
}
