import { useParams } from "react-router";
import UserQuiz from "../components/user-quiz/user-quiz.component";
import questionSet from "../data/question-sets.json";
import { useInjection } from "inversify-react";
import IQuizService from "../services/IQuizService";
import IIncorrectAnswerRepositoryService from "../services/IIncorrectAnswerRepositoryService";

export default function UserQuizPage() {
  let { setNumber } = useParams();

  const quizService = useInjection<IQuizService>('IQuizService')
  const incorrectQuestions = useInjection<IIncorrectAnswerRepositoryService>('IIncorrectAnswerRepositoryService')

  let questionIds: number[] = [];

  if (setNumber === "random") {
    questionIds = quizService.getRandomQuestionIDS();
  }
  else if (setNumber === "wrong"){
    questionIds = incorrectQuestions.getWrongAnswers();
  }
   else {
    let setNumberInteger = !!setNumber ? parseInt(setNumber) : 0;
    if (!setNumberInteger) {
      setNumberInteger = 0;
    }

    if (setNumberInteger < 0) setNumberInteger = 0;

    if (setNumberInteger > questionSet.sets.length - 1) {
      setNumberInteger = 0;
    }

    questionIds = questionSet.sets[setNumberInteger];
  }

  return <UserQuiz questionIds={questionIds} />;
}
