import { Flex, Container } from "@mantine/core";
import TextQuiz from "../../../models/Quizes/TextQuiz";
import Question from "../../Basic/Question/question.component";
import Answer, { AnswerState } from "../../Basic/Answer/answer.component";
import HintComponent from "../Hint/hint.component";

interface TextQuizComponentProps {
  textQuiz: TextQuiz;
  markAnswer?: boolean;
  userAnswer?: number;
  disabled?: boolean;
  onAnswer?: (index: number) => void;
}

export default function TextQuizComponent(props: TextQuizComponentProps) {
  const { textQuiz, markAnswer, userAnswer, disabled, onAnswer } = props;

  const getAnswerState = (index: number) => {
    if (!!markAnswer) {
      if (index === textQuiz.correctAnswer) {
        return AnswerState.CorrectAnswer;
      }
      if (
        index !== textQuiz.correctAnswer &&
        index === userAnswer
      ){
          return AnswerState.WrongAnswer;
      }
    }

    return AnswerState.Default;
  };

  return (
    <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <Question>{textQuiz.question}</Question>
      <Container sx={{
        width:"100%"
      }}>
        <Flex
          mih={50}
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          sx={{
            width: "100%",
          }}
        >
          {textQuiz.answers.map((answerText, i) => (
            <Answer
              key={i}
              clicked={() => {
                if (!!onAnswer) {
                  onAnswer(i);
                }
              }}
              disabled={disabled}
              answerState={getAnswerState(i)}
            >
              {answerText}
            </Answer>
          ))}
          <HintComponent quiz={textQuiz} />
        </Flex>
      </Container>
    </Flex>
  );
}
