import { Flex, Container} from "@mantine/core";
import Question from "../../Basic/Question/question.component";
import Answer, { AnswerState } from "../../Basic/Answer/answer.component";
import HintComponent from "../Hint/hint.component";
import ImageQuiz from "../../../models/Quizes/ImageQuiz";
import QuizImage from "../../Basic/QuizImage/QuizImage";

interface ImageQuizComponentProps {
  imageQuiz: ImageQuiz;
  markAnswer?: boolean;
  userAnswer?: number;
  disabled?: boolean;
  onAnswer?: (index: number) => void;
}

export default function ImageQuizComponent(props: ImageQuizComponentProps) {
  const { imageQuiz, markAnswer, userAnswer, disabled, onAnswer } = props;

  const getAnswerState = (index: number) => {
    if (!!markAnswer) {
      if (index === imageQuiz.correctAnswer) {
        return AnswerState.CorrectAnswer;
      }
      if (
        index !== imageQuiz.correctAnswer &&
        index === userAnswer
      )
        return AnswerState.WrongAnswer;
    }

    return AnswerState.Default;
  };

  return (
    <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
     <QuizImage
        alt={'Double Question'}
        src={imageQuiz.image}
      />
      <Question>{imageQuiz.question}</Question>
      <Container
        sx={{
          width: "100%",
        }}
      >
        <Flex
          mih={50}
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          sx={{
            width: "100%",
          }}
        >
          {!!imageQuiz.answers &&
            imageQuiz.answers.map((answerText, i) => (
              <Answer
                key={i}
                clicked={() => {
                  if (!!onAnswer) {
                    onAnswer(i);
                  }
                }}
                disabled={disabled}
                answerState={getAnswerState(i)}
              >
                {answerText}
              </Answer>
            ))}
          <HintComponent quiz={imageQuiz} />
        </Flex>
      </Container>
    </Flex>
  );
}
