import { useInjection } from "inversify-react";
import { UserAnswer } from "../../types/UserAnswer";
import IQuizService from "../../services/IQuizService";
import { Container, Flex } from "@mantine/core";
import QuizRendererComponent from "../quiz/QuizRenderer/quiz-renderer.component";

interface FinalReviewProps {
  questionIDs: number[];
  userAnswers: UserAnswer[];
}

export default function FinalReview({
  questionIDs,
  userAnswers,
}: FinalReviewProps) {
  const quizService = useInjection<IQuizService>("IQuizService");
  const totalCorrect = quizService.getCorrectAnswersCount(questionIDs, userAnswers);
  return (
    <Container>
      <Flex direction="column" gap="lg" align='center'>
        <h2 >Total Correct: <span style={{color:totalCorrect===questionIDs.length?'green':'red'}}>{totalCorrect}/{questionIDs.length}</span></h2>
        <h3>Questions</h3>
        {questionIDs.map((id, index) => (
          <QuizRendererComponent 
            favoriteIcon
            key={index}
            markAnswer
            userAnswer={userAnswers[index]}
            layoutForm
            disabled
            index={index+1}
            quiz={quizService.getQuestionById(id)} />
        ))}
      </Flex>
    </Container>
  );
}
