import { Flex } from '@mantine/core'
import data from '../../data/question-sets.json'
import { Link } from 'react-router-dom'
import styles from './question-sets.module.scss'
import IIncorrectAnswerRepositoryService from '../../services/IIncorrectAnswerRepositoryService'
import { useInjection } from 'inversify-react'

export default function QuestionSets(){


  const incorrectQuestions = useInjection<IIncorrectAnswerRepositoryService>('IIncorrectAnswerRepositoryService')

    return (
        <Flex gap='lg' align='center' justify='center' direction='column'>
            <h2>Question Sets</h2>
            <Flex className={styles.questionFlex} gap='lg' wrap='wrap' align='center' justify='center' direction='row'>
                {data.sets.map((_,index)=>{
                    return (
                        <QuestionLink key={index} setIndex={index.toString()} content={`Question Set ${index+1}`}/>
                    )
                })}
                <QuestionLink key={'random'} setIndex='random' content={`Question Set Random`}/>
                <QuestionLink disabled={incorrectQuestions.getWrongAnswers().length<=0} key={'wrong'} setIndex='wrong' content={`Question Set Wrong`}/>
            </Flex>
        </Flex>
    )
}

function QuestionLink({setIndex, content, disabled} : {setIndex: string, content:string, disabled?:boolean}){
    return (
        <Link className={css(styles.questionNav, !!disabled?styles.disabledLink:undefined)} to={`/questions/${setIndex}`}><div>{content}</div></Link>
    )
}

function css(...array: (string|undefined|null)[]){
    let className = ''

    array.forEach(value=>{
        if(!!value){
            className+=`${value} `
        }
    })

    if(className.length>0){
        return className.slice(0,-1);
    }
    return className
}