import styles from "./quiz-image.module.scss";

interface QuizImageProps {
  src: string;
  alt?: string;
}

export default function QuizImage(props: QuizImageProps) {
  const { src } = props;
  let alt = !!props.alt ? props.alt : "Quiz Image";

  return (
    <img
      alt={alt}
      className={src.indexOf("sign") > 0 ? styles.sign : styles.default}
      src={`../img/${src}.png`}
    />
  );
}
