import { useInjection } from "inversify-react";
import { useEffect, useState } from "react";
import IQuizService from "../../services/IQuizService";
import QuizRendererComponent from "../quiz/QuizRenderer/quiz-renderer.component";
import { UserAnswer } from "../../types/UserAnswer";
import FinalReview from "../final-review/final-review.component";
import { Center, Flex } from "@mantine/core";

interface UserQuizProps {
  questionIds: number[];
}

export default function UserQuiz({ questionIds }: UserQuizProps) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const quizService = useInjection<IQuizService>("IQuizService");
  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
  const [finalView, setFinalView] = useState(false);

  useEffect(() => {}, [currentQuestion]);

  return !finalView ? (
    <Center>
      <Flex align="center" justify="center" direction="column">
        <Flex align="center" justify="flex-end" style={{ width: "100%" }}>
          <p>
            <b>Question:</b> {currentQuestion + 1}/{questionIds.length}
          </p>
        </Flex>

        <QuizRendererComponent
          quiz={quizService.getQuestionById(questionIds[currentQuestion])}
          onAnswer={(answer) => {
            userAnswers.push(answer);
            setUserAnswers(userAnswers);
            if (currentQuestion + 1 === questionIds.length) {
              setFinalView(true);
              quizService.adjustCorrectAnswers(questionIds, userAnswers)
            } else {
              setCurrentQuestion(currentQuestion + 1);
            }
          }}
        />
      </Flex>
    </Center>
  ) : (
    <FinalReview questionIDs={questionIds} userAnswers={userAnswers} />
  );
}
