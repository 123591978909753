import { injectable } from "inversify";

@injectable()
export default class IncorrectAnswerRepositoryService
  implements IncorrectAnswerRepositoryService
{
  private wrongAnswerSet: Map<number, number>;

  private wrongAnswerKey = "wrongAnswers";

  constructor() {
    this.wrongAnswerSet = new Map<number, number>();
    this.loadData();
  }

  setAnswer(id: number, isCorrect: boolean) {
    if (this.wrongAnswerSet.has(id)) {
      const difference = !!isCorrect ? -1 : 1;

      this.wrongAnswerSet.set(id, this.wrongAnswerSet.get(id)! + difference);

      if(this.wrongAnswerSet.get(id)!<=0){
         this.wrongAnswerSet.delete(id);
      }
    }
    else if(!isCorrect){
        this.wrongAnswerSet.set(id, 1);
    }

    this.save();
  }
  getWrongAnswers(): number[] {
    return Array.from(this.wrongAnswerSet.keys());
  }

  save() {
    let resultString = "";
    this.wrongAnswerSet.forEach((wrongAnswerValue, wrongAnswerKey) => {
      resultString += `${wrongAnswerKey}:${wrongAnswerValue},`;
    });
    resultString = resultString.slice(0, -1);

    window.localStorage.setItem(this.wrongAnswerKey, resultString);
  }

  loadData() {
    const value = window.localStorage.getItem(this.wrongAnswerKey);
    if (!!value) {
      const elements = value.split(",");
      elements.forEach((element) => {
        const keyValue = element.split(":");
        if (keyValue.length !== 2) return;

        const key = parseInt(keyValue[0]);
        const value = parseInt(keyValue[1]);

        this.wrongAnswerSet.set(key, value);
      });
    }
  }
}
