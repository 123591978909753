import { useState } from "react";
import Quiz from "../../../models/Quizes/Quiz";
import { IconInfoCircle } from "@tabler/icons-react";
import { ActionIcon, Flex } from "@mantine/core";

export default function HintComponent({ quiz }: { quiz: Quiz }) {
  const [hintVisible, setHintVisible] = useState(false);
  return !!quiz.hint ? (
    <Flex>
      <ActionIcon onClick={e=>{
            e.preventDefault();
            setHintVisible(!hintVisible);
        }}>
        <IconInfoCircle size="1.125rem"  />
      </ActionIcon>
      {!!hintVisible&&<p>{quiz.hint}</p>}
    </Flex>
  ) : <></>;
}
