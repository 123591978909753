import { injectable } from "inversify";
import IFavoriteQuestionRepositoryService from "../IFavoriteQuestionRepositoryService";

@injectable()
export default class FavoriteQuestionRepositoryService
  implements IFavoriteQuestionRepositoryService
{
  private favoriteAnswerSet: Set<number>;

  private favoriteAnswerKey = "favoriteAnswers";

  constructor() {
    this.favoriteAnswerSet = new Set<number>();
    this.loadData();
  }

  setFavorite(id: number, isFavorite: boolean) {
    if (!this.favoriteAnswerSet.has(id) && isFavorite) {
      this.favoriteAnswerSet.add(id);
    } else if (!isFavorite && this.favoriteAnswerSet.has(id)) {
      this.favoriteAnswerSet.delete(id);
    }

    this.save();
  }
  getFavorites(): number[] {
    return Array.from(this.favoriteAnswerSet.keys());
  }

  isFavorite(id : number):boolean{
    return this.favoriteAnswerSet.has(id);
  }

  save() {
    let resultString = "";
    this.favoriteAnswerSet.forEach((value) => {
      resultString += `${value},`;
    });
    resultString = resultString.slice(0, -1);

    window.localStorage.setItem(this.favoriteAnswerKey, resultString);
  }

  loadData() {
    const value = window.localStorage.getItem(this.favoriteAnswerKey);
    if (!!value) {
      const elements = value.split(",");
      elements.forEach((element) => {
        const key = parseInt(element);

        if (!this.favoriteAnswerSet.has(key)) this.favoriteAnswerSet.add(key);
      });
    }
  }
}
