import "reflect-metadata";
import React from "react";
import logo from "./logo.svg";
import "./App.css";
import BootstrapApp from "./bootstrapper";
import { Container } from "inversify";
import { Provider } from "inversify-react";
import RouterPage from "./pages/RouterPage";
import { MantineProvider } from "@mantine/core";

class App extends React.Component {
  private readonly container: Container = new Container();
  constructor(props: {}) {
    super(props);
    BootstrapApp(this.container);
  }
  render() {
    return (
      <MantineProvider withGlobalStyles withNormalizeCSS>
        <Provider container={this.container}>
          <React.Fragment>
            <RouterPage />
          </React.Fragment>
        </Provider>
      </MantineProvider>
    );
  }
}

export default App;
