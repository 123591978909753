import IQuizService from "../IQuizService";
import { inject, injectable } from "inversify";
import data from "../../data/questions.json";
import { UserAnswer } from "../../types/UserAnswer";
import Quiz from "../../models/Quizes/Quiz";
import TextQuiz from "../../models/Quizes/TextQuiz";
import TextPromptQuiz from "../../models/Quizes/TextPromptQuiz";
import ImageQuiz from "../../models/Quizes/ImageQuiz";
import ImageDoubleQuestionQuiz from "../../models/Quizes/ImageDoubleQuestionQuiz";
import IIncorrectAnswerRepositoryService from "../IIncorrectAnswerRepositoryService";

@injectable()
export default class QuizService implements IQuizService {
  private allQuestions: any[];
  private questMap: Map<number, any>;
  @inject('IIncorrectAnswerRepositoryService')
  private incorrectAnswerRepository!: IIncorrectAnswerRepositoryService;
  constructor() {
    this.allQuestions = [];
    this.questMap = new Map<number, any>();
    data.sections.forEach((x) => {
      x.questions.forEach((q) => {
        this.allQuestions.push(q);
        this.questMap.set(q.id, q);
      });
    });
  }

  public getAllQuestions(): any[] {
    return this.allQuestions;
  }

  public getQuestionById(id: number): any {
    if (!this.questMap.has(id)) throw new Error(`Invalid ID: ${id} provided`);
    return this.questMap.get(id);
  }

  public getCorrectAnswersCount(ids: number[], userAnswers: UserAnswer[]): number {
    let totalCorrect = 0;

    for (let i = 0; i < ids.length; i++) {
      const question = this.getQuestionById(ids[i]);

      const isCorrect = !!this.isAnswerCorrect(question, userAnswers[i]);
      totalCorrect += isCorrect ? 1 : 0;
    }

    return totalCorrect;
  }

  public adjustCorrectAnswers(ids: number[], userAnswers: UserAnswer[]): number {
    let totalCorrect = 0;

    for (let i = 0; i < ids.length; i++) {
      const question = this.getQuestionById(ids[i]);

      const isCorrect = !!this.isAnswerCorrect(question, userAnswers[i]);
      this.incorrectAnswerRepository.setAnswer(ids[i], isCorrect);
    }

    return totalCorrect;
  }

  public isAnswerCorrect(question: any, userAnswer: UserAnswer): boolean {
    const quizCasted = question as Quiz;
    switch (quizCasted.key) {
      case "textquiz":
        const textQuiz = question as TextQuiz;
        return parseInt(textQuiz.correctAnswer.toString()) === userAnswer;
      case "textprompt":
        const textprompt = question as TextPromptQuiz;
        if (!userAnswer) return false;
        if(!(userAnswer as string).replaceAll){
            console.log(userAnswer);
            return false;
        }
        return (
          textprompt.correctAnswer.replaceAll(" ", "") ===
          (userAnswer as string).replaceAll(" ", "")
        );
      case "imageDoubleQuestion":
        const imageDoubleQuestion = question as ImageDoubleQuestionQuiz;
        return (
          this.isAnswerCorrect(
            imageDoubleQuestion.questions[0],
            (userAnswer as (string | number)[])[0]
          ) &&
          this.isAnswerCorrect(
            imageDoubleQuestion.questions[1],
            (userAnswer as (string | number)[])[1]
          )
        );
      case "imagequiz":
        const imageQuiz = question as ImageQuiz;
        return parseInt(imageQuiz.correctAnswer.toString()) === userAnswer;
      default:
        return false;
    }
  }

  private defaultCount = 6;
  private imageCount = 6;
  private signCount = 7;
  private extraCount = 5;

  public getRandomQuestionIDS(): number[] {
    let questionIDs: number[] = [];

    const counts = [this.defaultCount, this.imageCount, this.signCount, this.extraCount];

    for(let i = 0; i<4; i++){
        const ids = []

        for(let j = 0; j<data.sections[i].questions.length; j++){
            ids.push(data.sections[i].questions[j].id);
        }

        this.shuffleArray(ids);

        for(let j =0;j<counts[i];j++){
            const pop = ids.pop();
            if(!!pop)
                questionIDs.push(pop);
            else
                questionIDs.push(10001);
        }
    }

    return questionIDs;
  }

  private shuffleArray(array: any[]) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }
}
