import { Container } from 'inversify';
import QuizService from './services/implementations/QuizService';
import IncorrectAnswerRepositoryService from './services/implementations/IncorrectAnswerRepositoryService';
import FavoriteQuestionRepositoryService from './services/implementations/FavoriteQuestionRepositoryService';


export default function BootstrapApp(container: Container) {
    initializeServices(container);
}

function initializeServices(container: Container) {
    container.bind("IQuizService").to(QuizService).inSingletonScope(); 
    container.bind("IIncorrectAnswerRepositoryService").to(IncorrectAnswerRepositoryService).inSingletonScope(); 
    container.bind("IFavoriteQuestionRepositoryService").to(FavoriteQuestionRepositoryService).inSingletonScope(); 
      
}