import { Container, Flex } from "@mantine/core";
import { useInjection } from "inversify-react";
import IQuizService from "../../services/IQuizService";
import QuizRendererComponent from "../quiz/QuizRenderer/quiz-renderer.component";
import React from "react";
import IFavoriteQuestionRepositoryService from "../../services/IFavoriteQuestionRepositoryService";

export default function FavoriteSet() {
  const quizService = useInjection<IQuizService>("IQuizService");
  const favoriteService = useInjection<IFavoriteQuestionRepositoryService>("IFavoriteQuestionRepositoryService");


  const favorites = favoriteService.getFavorites();

  return (
    <Container size={"sm"}>
      <Flex
        mih={50}
        gap="sm"
        justify="flex-start"
        align="center"
        direction="column"
        wrap="wrap"
      >
        {favorites.length>0 ? favorites.map((questionId, index) => {
          return (
            <React.Fragment key={index}>
              <QuizRendererComponent
                index={index + 1}
                markAnswer
                disabled
                quiz={quizService.getQuestionById(questionId)}
                favoriteIcon
                layoutForm
              />
              <hr style={{width:'60%'}}/>
            </React.Fragment>
          );
        }): <h1>No Saved Questions</h1>}
      </Flex>
    </Container>
  );
}
