import { Container, Flex } from "@mantine/core";
import ImageQuiz from "../../models/Quizes/ImageQuiz";
import ImageQuizComponent from "../quiz/ImageQuiz/image-quiz.component";
import TextPromptQuiz from "../../models/Quizes/TextPromptQuiz";
import TextPromptComponent from "../quiz/TextPrompt/text-prompt.component";
import { useInjection } from "inversify-react";
import IQuizService from "../../services/IQuizService";
import QuizRendererComponent from "../quiz/QuizRenderer/quiz-renderer.component";
import React from "react";

export default function ReviewQuiz() {
  const quizService = useInjection<IQuizService>("IQuizService");

  const allQuiz = quizService.getAllQuestions();

  return (
    <Container size={"sm"}>
      <Flex
        mih={50}
        gap="sm"
        justify="flex-start"
        align="center"
        direction="column"
        wrap="wrap"
      >
        {allQuiz.map((x, index) => {
          return (
            <React.Fragment key={index}>
              <QuizRendererComponent
                index={index + 1}
                markAnswer
                disabled
                quiz={x}
                favoriteIcon
                layoutForm
              />
              <hr style={{width:'60%'}}/>
            </React.Fragment>
          );
        })}
      </Flex>
    </Container>
  );
}
