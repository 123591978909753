import { Center, Flex, Container, Image } from "@mantine/core";
import ImageDoubleQuestionQuiz from "../../../models/Quizes/ImageDoubleQuestionQuiz";
import SimpleQuizRendererComponent from "../QuizRenderer/simple-quiz-renderer.component";
import QuizImage from "../../Basic/QuizImage/QuizImage";
import { useEffect, useState } from "react";

interface ImageDoubleQuestionProps {
  imageQuiz: ImageDoubleQuestionQuiz;
  markAnswer?: boolean;
  userAnswer?: (number | string)[];
  disabled?: boolean;
  longView?: boolean;
  onAnswer?: (answer: (number | string)[]) => void;
}

export default function ImageDoubleQuestion(props: ImageDoubleQuestionProps) {
  const { imageQuiz, markAnswer, userAnswer, disabled, longView, onAnswer } =
    props;

  const [quizAnswers, setQuizAnswers] = useState<(number | string)[]>([-1, -1]);

  const [slide, setSlide] = useState(0);

  useEffect(()=>{
    setSlide(0);
    setQuizAnswers([-1,-1])
  }, [imageQuiz.id])

  return (
    <Flex
      mih={50}
      gap="md"
      justify="center"
      align="center"
      direction="column"
      wrap="wrap"
    >
      <QuizImage alt={"Double Question"} src={imageQuiz.image} />

      <Container>
        <Flex
          mih={50}
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          sx={{
            width: "100%",
          }}
        >
          {!!longView &&
            imageQuiz.questions.map((x, index) => (
              <SimpleQuizRendererComponent
                key={index}
                quiz={x}
                markAnswer={markAnswer}
                disabled={disabled}
                userAnswer={!!userAnswer ? userAnswer[index] : undefined}
                onAnswer={(answer) => {
                  if (!!onAnswer) {
                    quizAnswers[index] = answer;
                    setQuizAnswers(quizAnswers);
                    if (!quizAnswers.includes(-1)) {
                      onAnswer(quizAnswers);
                    }
                  }
                }}
              />
            ))}
          {!longView && (
            <SimpleQuizRendererComponent
              quiz={imageQuiz.questions[slide]}
              markAnswer={markAnswer}
              disabled={disabled}
              userAnswer={!!userAnswer ? userAnswer[slide] : undefined}
              onAnswer={(answer) => {
                quizAnswers[slide] = answer;
                setQuizAnswers(quizAnswers);
                
                if(slide===0)
                    setSlide(slide + 1);
                if (!!onAnswer) {
                  if (!quizAnswers.includes(-1)) {
                    onAnswer(quizAnswers);
                  }
                }
              }}
            />
          )}
        </Flex>
      </Container>
    </Flex>
  );
}
