import ImageQuiz from "../../../models/Quizes/ImageQuiz";
import Quiz from "../../../models/Quizes/Quiz";
import TextPromptQuiz from "../../../models/Quizes/TextPromptQuiz";
import TextQuiz from "../../../models/Quizes/TextQuiz";
import ImageQuizComponent from "../ImageQuiz/image-quiz.component";
import TextPromptComponent from "../TextPrompt/text-prompt.component";
import TextQuizComponent from "../TextQuiz/text-quiz.component";

interface SimpleQuizRendererProps {
  quiz: any;
  markAnswer?: boolean;
  userAnswer?: number | string;
  disabled?: boolean;
  onAnswer?: (index: number | string) => void;
}

export default function SimpleQuizRendererComponent(props: SimpleQuizRendererProps) {
  const { quiz, onAnswer } = props;

  const quizCasted = quiz as Quiz;

  const sharedAttributes = {
    markAnswer: props.markAnswer,
    disabled: props.disabled,
  };

  switch (quizCasted.key) {
    case "textquiz":
      const textQuiz = quiz as TextQuiz;
      textQuiz.correctAnswer = parseInt(quiz.correctAnswer);
      return (
        <TextQuizComponent
          userAnswer={
            props.userAnswer!==undefined ? (props.userAnswer as number) : undefined
          }
          textQuiz={quiz as TextQuiz}
          onAnswer={onAnswer}
          {...sharedAttributes}
        />
      );
    case "textprompt":
      return (
        <TextPromptComponent
          userAnswer={
            props.userAnswer!==undefined ? (props.userAnswer as string) : undefined
          }
          textPrompt={quiz as TextPromptQuiz}
          onAnswer={onAnswer}
          {...sharedAttributes}
        />
      );
    case "imagequiz":
      const imageQuiz = quiz as ImageQuiz;
      imageQuiz.correctAnswer = parseInt(quiz.correctAnswer);
      return (
        <ImageQuizComponent
          userAnswer={
            props.userAnswer!==undefined ? (props.userAnswer as number) : undefined
          }
          imageQuiz={imageQuiz}
          onAnswer={onAnswer}
          {...sharedAttributes}
        />
      );
    default:
        return <p>{quizCasted.key}</p>
  }
}
